<template>
  <div class="d-flex justify-content-between new-block">
    <div>
      <h2 class="float-start">
        {{
          $t("MapView.EditMap", {}, { locale: this.$store.state.activeLang })
        }}
      </h2>
    </div>
    <div>
      <ActionButtons
        v-if="detailRequestEnd && mapData.isOk"
        @methodSave="onSubmit('btn-save')"
        :isNewRecord="false"
      />
    </div>
  </div>
  <SetLoader v-if="!detailRequestEnd" />
  <div v-if="errors.length > 0" class="alert alert-warning">
    <ul class="mb-0">
      <li v-for="error in errors" v-bind:key="error">
        {{ error }}
      </li>
    </ul>
  </div>
  <form
    v-show="detailRequestEnd && mapData.isOk"
    class="form-Map-edit pe-2 ps-2"
    novalidate
  >
    <div class="row row-cols-1">
      <div class="col col-md-6">
        <label for="Name" class="form-label required"
          >{{
            $t("MapView.Name", {}, { locale: this.$store.state.activeLang })
          }}
        </label>
        <input
          type="text"
          class="form-control"
          id="Name"
          v-model="mapData.name"
          :autocomplete="this.$isAutoComplete"
          :spellcheck="this.$isTextSpellCheck"
          required
        />
      </div>
      <div class="col col-md-6">
        <label for="FormulaName" class="form-label required">{{
          $t(
            "MapView.FormulaName",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}</label>
        <input
          type="text"
          class="form-control"
          id="FormulaName"
          :disabled="true"
          v-model="mapData.formulaName"
          :autocomplete="this.$isAutoComplete"
          :spellcheck="this.$isTextSpellCheck"
          required
        />
      </div>
    </div>
    <div class="row row-cols-1 mt-3 mb-3">
      <div class="col col-md-4">
        <label for="SpatialField" class="form-label required">{{
          $t(
            "MapView.SpatialField",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}</label>
        <FormSelect
          type="SelectWithRemoteDataAndInternalSearch"
          :isDetailIconShow="true"
          :routeLink="
            String.format(
              '#/CustomObject/EditField/{0}&fieldId=',
              this.$route.params.customObjectId
            )
          "
          @onChange="onChangeSpatialField"
          :selectedData="selectedSpatialField"
          :allowEmpty="false"
          :isParameters="true"
          :isGatewayRequest="true"
          :requestUrl="
            String.format(
              '/Lcdp-SummaryMapViewableFields?parentId={0}',
              this.$route.params.customObjectId
            )
          "
        />
      </div>
      <div class="col col-md-4">
        <label for="LabelTitle" class="form-label required">{{
          $t("MapView.LabelTitle", {}, { locale: this.$store.state.activeLang })
        }}</label>
        <FormSelect
          type="SelectWithRemoteDataAndInternalSearch"
          @onMultipleSelectChange="onChangeLabelTitle"
          :selectedData="selectedLabelTitle"
          :isMultiple="true"
          :allowEmpty="false"
          :isParameters="true"
          :isGatewayRequest="true"
          :requestUrl="
            String.format(
              '/Lcdp-SummaryFields?coId={0}',
              this.$route.params.customObjectId
            )
          "
        />
      </div>
      <div class="col col-md-4">
        <label for="DefaultMap" class="form-label required">{{
          $t("MapView.DefaultMap", {}, { locale: this.$store.state.activeLang })
        }}</label>
        <FormSelect
          type="SelectWithLocalData"
          :selectedData="selectedDefaultMap"
          :allowEmpty="false"
          :data="defaultMapData"
          @onChange="onChangeForDefaultMap"
        />
      </div>
    </div>
    <ActionButtons
      v-if="$isMobile()"
      @methodSave="onSubmit('btn-save')"
      :isNewRecord="false"
    />
  </form>
</template>
<script>
import $ from "jquery";
export default {
  name: "CustomObjectNewMap",
  components: {},
  data() {
    return {
      submitButtonHtml: String.format(
        '<span class="loading-block"><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span><span> {0}</span></span>',
        this.$t(
          "Buttons.PleaseWait",
          {},
          { locale: this.$store.state.activeLang }
        )
      ),
      disabledButtonClasses: ".btn-save",
      errors: [],
      detailRequestEnd: false,
      mapData: {},
      selectedSpatialField: [],
      selectedLabelTitle: [],
      selectedDefaultMap: [],
      selectValueReset: false,
      nullObject: [{ key: null, value: null }],
      defaultMapData: [
        {
          key: "1",
          value: this.$t(
            "MapView.OpenStreetMap",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: "2",
          value: this.$t(
            "MapView.Google",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: "3",
          value: this.$t(
            "MapView.Atlas",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
      ],
    };
  },
  methods: {
    onChangeSpatialField: function (selected) {
      this.mapData.spatialFieldPublicId = selected.key;
    },
    onChangeLabelTitle(ids) {
      this.mapData.titleFieldPublicIds = ids;
    },
    onChangeForDefaultMap: function (selected) {
      this.mapData.defaultMap = selected.key;
    },
    onSubmit(buttonName) {
      var form = $(".form-Map-edit");
      form.addClass("was-validated");
      if (
        !form[0].checkValidity() ||
        form.find(".ms-invalid:visible").length > 0
      ) {
        return;
      }

      var button = $(String.format(".{0}", buttonName)),
        firstSpan = button.find("span:first"),
        loadingBlock = button.find(".loading-block"),
        disabledButtons = $(this.disabledButtonClasses);

      disabledButtons.prop("disabled", true);
      firstSpan.hide();
      if (loadingBlock.length === 0) {
        firstSpan.after(this.submitButtonHtml);
        loadingBlock = button.find(".loading-block");
      } else {
        loadingBlock.show();
      }

      this.errors = [];
      this.$prodGatewayAxios
        .post("/Lcdp-MapViewEdit", { ...this.mapData })
        .then((r) => {
          var response = r.data;
          if (response.isOk) {
            this.$router.push(
              "/CustomObject/Map/" + this.$route.params.customObjectId
            );
          } else {
            firstSpan.show();
            loadingBlock.hide();
            disabledButtons.prop("disabled", false);
            this.errors.push(response.msg);
          }
        })
        .catch((r) => {
          firstSpan.show();
          loadingBlock.hide();
          disabledButtons.prop("disabled", false);
          this.errors.push(r);
        });
    },
    getDefaultMapName(valueTypeId) {
      var text = this.$t(
        "MapView.OpenStreetMap",
        {},
        { locale: this.$store.state.activeLang }
      );
      if (valueTypeId == 2) {
        text = this.$t(
          "MapView.Google",
          {},
          { locale: this.$store.state.activeLang }
        );
      } else if (valueTypeId == 3) {
        text = this.$t(
          "MapView.Atlas",
          {},
          { locale: this.$store.state.activeLang }
        );
      }
      return text;
    },
    getMap() {
      this.errors = [];
      this.$prodGatewayAxios
        .get(
          String.format(
            "/Lcdp-MapViewGet?id={0}&coId={1}",
            this.$route.params.mapId,
            this.$route.params.customObjectId
          )
        )
        .then((response) => {
          this.mapData = response.data;
          this.detailRequestEnd = true;
          if (response.data.isOk) {
            if (!String.isNullOrWhiteSpace(response.data.defaultMap)) {
              this.selectedDefaultMap.push({
                key: response.data.defaultMap.toString(),
                value: this.getDefaultMapName(response.data.defaultMap),
              });
            }
            if (
              !String.isNullOrWhiteSpace(this.mapData.spatialFieldName) &&
              !String.isNullOrWhiteSpace(this.mapData.spatialFieldPublicId)
            ) {
              this.selectedSpatialField.push({
                key: this.mapData.spatialFieldPublicId,
                value: this.mapData.spatialFieldName,
              });
            }

            if (response.data.titleFieldPublicIds != null) {
              var listTitleIds = response.data.titleFieldPublicIds;
              var listNames = response.data.titleFieldNames;
              var self = this;
              if (listTitleIds.includes("|") && listNames.includes("|")) {
                listTitleIds = listTitleIds.split(this.$systemSeparator);
                listNames = listNames.split(this.$systemSeparator);
                $.each(listTitleIds, function (i, v) {
                  if (v !== "" && listNames !== "") {
                    self.selectedLabelTitle.push({
                      key: v,
                      value: listNames[i],
                    });
                  }
                });
              } else {
                self.selectedLabelTitle.push({
                  key: listTitleIds,
                  value: listNames,
                });
              }
            }
          } else {
            this.errors.push(response.data.msg);
          }
        })
        .catch(function (error) {
          //alert(error);
        });
    },
  },
  mounted() {
    this.getMap();
  },
};
</script>
